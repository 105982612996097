import React from 'react';
import { linkResolver } from '../utils/link_resolver'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as prismicH from "@prismicio/helpers";

const components = {
  hyperlink: ({ node, children }) => (
    <a href={node.data.url} target="_blank" rel="noreferrer nofollow">{children}</a>
  ),
}

const ServicesList = ({primary, items}) => {
  return (
    <div className="w-full pt-12 pb-6 border-t-2 border-grey">
      <div className="w-11/12 mx-auto max-w-screen-2xl">
        <div className="mb-12">
          <h2 className="font-brand leading-none font-black text-4xl md:text-5xl text-blue">
            {primary.heading}
          </h2>
        </div>
        {items.map((entry,i) => {
          console.log('entry.text.richText: ', entry.text.richText);
          return (
          <div key={i} className="flex-none md:flex justify-between items-center gap-12 mb-12" data-sal-duration="1000" data-sal="slide-up" data-sal-easing="ease">
            <div className="w-full md:w-6/12">
              <GatsbyImage style={{ width: "100%", height: "100%", display: "block", objectFit: 'cover' }} image={entry.image.gatsbyImageData} alt={entry.image.alt ? entry.image.alt : ''} />
            </div>
            <div className="w-full md:w-6/12 pt-12 md:pt-0">
              <div className="font-sans prose prose-lg prose-h2:font-brand prose-h2:mb-6 leading-normal">
              {prismicH.isFilled.richText(entry.text.richText) && (
                <PrismicRichText 
                  linkResolver={linkResolver} 
                  field={entry.text.richText} 
                  components={components}
                />
              )}
              </div>
            </div>
          </div>
          )
        })}
      </div>
    </div>
  );
}

export default ServicesList;
